import React, {Component} from 'react';
import axios from 'axios';
import Spinner from '../../components/UI/Spinner/Spinner';
import Error from '../../components/UI/Error/Error';
import Aux from '../../hoc/Auxilary/Auxilary';
import Measure from '../../components/UI/Measure/Measure';
import DustMeasure from '../../components/UI/Measure/DustMeasure';
import {faClock, faSmog, faTemperatureHigh, faTint} from "@fortawesome/free-solid-svg-icons";
import LineChart from '../../components/UI/Chart/LineChart';
import LineChartWeekly from '../../components/UI/Chart/LineChartWeekly';
import Button from '../../components/UI/Button/Button';
import ReactGA from 'react-ga';

class Measures extends Component {
  state = {
    last: {
      pm10: null,
      pm25: null,
      temperature: null,
      humidity: null,
      wifiStrength: null,
      timestamp: null
    },
    lastDay: null,
    lastWeek: null,
    limits: {
      pm10: [
        {'min': 0, 'max': 25, 'quality': 'Bardzo dobra', 'color': '#00b050'},
        {'min': 25, 'max': 50, 'quality': 'Dobra', 'color': '#92d050'},
        {'min': 50, 'max': 90, 'quality': 'Umiarkowana', 'color': '#ffff00'},
        {'min': 90, 'max': 135, 'quality': 'Dostateczna', 'color': '#ffc000'},
        {'min': 135, 'max': 180, 'quality': 'Zła', 'color': '#ff0000'},
        {'min': 180, 'max': 9999, 'quality': 'Bardzo zła', 'color': '#c00000'},
      ],
      pm25: [
        {'min': 0, 'max': 15, 'quality': 'Bardzo dobra', 'color': '#00b050'},
        {'min': 15, 'max': 30, 'quality': 'Dobra', 'color': '#92d050'},
        {'min': 30, 'max': 55, 'quality': 'Umiarkowana', 'color': '#ffff00'},
        {'min': 55, 'max': 82, 'quality': 'Dostateczna', 'color': '#ffc000'},
        {'min': 82, 'max': 110, 'quality': 'Zła', 'color': '#ff0000'},
        {'min': 110, 'max': 9999, 'quality': 'Bardzo zła', 'color': '#c00000'},
      ],
    },
    error: false,
    loading: true,
    errorDaily: false,
    loadingDaily: true,
    showChartLastDayPm25: true,
    showChartLastDayPm10: false,
    errorWeekly: false,
    loadingWeekly: true,
    showChartLastWeekPm25: true,
    showChartLastWeekPm10: false
  };

  componentDidMount() {
    this.updateData();
    this.updateDailyData();
    this.updateWeeklyData();
  }

  updateData = () => {
    this.setState({loading: true});
    const options = {
      method: 'GET',
      mode: 'no-cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      withCredentials: true,
      credentials: 'same-origin',
    };
    axios.get('https://smog.smartgroup.eu/data.php', options)
      .then(response => {
        this.setState({last: response.data, loading: false})
      })
      .catch(error => {
        this.setState({error: true, loading: false})
      });

    ReactGA.event({
      category: 'API call',
      action: 'Fetch last measure'
    });

    setTimeout(
      this.updateData.bind(this),
      300000
    );
  };

  updateDailyData = () => {
    this.setState({loading: true});
    const options = {
      method: 'GET',
      mode: 'no-cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      withCredentials: true,
      credentials: 'same-origin',
    };

    axios.get('https://smog.smartgroup.eu/data.php?mode=day', options)
      .then(response => {
        this.setState({lastDay: response.data, loadingDaily: false})
      })
      .catch(error => {
        this.setState({errorDaily: true, loadingDaily: false})
      });

    ReactGA.event({
      category: 'API call',
      action: 'Fetch last 24h measures'
    });
  };

  updateWeeklyData = () => {
    this.setState({loading: true});
    const options = {
      method: 'GET',
      mode: 'no-cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      withCredentials: true,
      credentials: 'same-origin',
    };

    axios.get('https://smog.smartgroup.eu/data.php?mode=week', options)
      .then(response => {
        this.setState({lastWeek: response.data, loadingWeekly: false})
      })
      .catch(error => {
        this.setState({errorWeekly: true, loadingWeekly: false})
      });

    ReactGA.event({
      category: 'API call',
      action: 'Fetch last 24h measures'
    });
  };

  handleDailyChartSwitch = () => {
    const showDailyPm10 = this.state.showChartLastDayPm10;
    const showDailyPm25 = this.state.showChartLastDayPm25;

    this.setState({
      showChartLastDayPm10: !showDailyPm10,
      showChartLastDayPm25: !showDailyPm25
    });

    ReactGA.event({
      category: 'Charts',
      action: 'Switch last 24h chart'
    });
  };

  handleWeeklyChartSwitch = () => {
    const showWeeklyPm10 = this.state.showChartLastWeekPm10;
    const showWeeklyPm25 = this.state.showChartLastWeekPm25;

    this.setState({
      showChartLastWeekPm10: !showWeeklyPm10,
      showChartLastWeekPm25: !showWeeklyPm25
    });

    ReactGA.event({
      category: 'Charts',
      action: 'Switch last week chart'
    });
  };

  render() {
    let measureData = null;

    if (this.state.loading) {
      measureData = <Spinner/>;
    }

    if (this.state.error) {
      measureData = <Error>Wystąpił błąd podczas pobierania danych. Spróbuj później...</Error>
    }

    if (!this.state.loading && !this.state.error) {
      measureData = <Aux>
        <DustMeasure type="PM 10" value={this.state.last.pm10} limit={this.state.limits.pm10} unit="µg/m³"
                     icon={faSmog}/>
        <DustMeasure type="PM 2.5" value={this.state.last.pm25} limit={this.state.limits.pm25} unit="µg/m³"
                     icon={faSmog}/>
        <Measure type="Temperatura" value={this.state.last.temperature} unit="&deg;C" icon={faTemperatureHigh}/>
        <Measure type="Wilgotność" value={this.state.last.humidity} unit="%" icon={faTint}/>
        <Measure type="Data ostatniego pomiaru" value={this.state.last.timestamp} inline={true} small={true}
                 icon={faClock}/>
      </Aux>;
    }

    let lastDayChart = null;

    if (this.state.loadingDaily) {
      lastDayChart = <Spinner/>;
    }

    if (this.state.errorDaily) {
      lastDayChart = <Error>Wystąpił błąd podczas pobierania danych. Spróbuj później...</Error>
    }

    if (!this.state.loadingDaily && !this.state.errorDaily) {
      const switchButtons = <Aux>
        <Button clickHandler={this.handleDailyChartSwitch} current={this.state.showChartLastDayPm25}>PM 2.5</Button>
        <Button clickHandler={this.handleDailyChartSwitch} current={this.state.showChartLastDayPm10}>PM 10</Button>
      </Aux>;

      if (this.state.showChartLastDayPm10) {
        lastDayChart =
          <LineChart
            title="PM 10 - ostatnie 24 godziny"
            type="line"
            data={{
              labels: this.state.lastDay.map(result => result.hour + ':00'),
              yRegions: [
                {
                  label: "Norma",
                  start: 0,
                  end: 50,
                  options: { labelPos: 'right' }
                }
              ],
              datasets: [
                {
                  name: 'PM 10',
                  color: 'violet',
                  values: this.state.lastDay.map(result => result.pm10)
                }
              ]
            }}
          >
            {switchButtons}
          </LineChart>
      }
      if (this.state.showChartLastDayPm25) {
        lastDayChart =
          <LineChart
            title="PM 2.5 - ostatnie 24 godziny"
            type="line"
            data={{
              labels: this.state.lastDay.map(result => result.hour + ':00'),
              yRegions: [
                {
                  label: "Norma",
                  start: 0,
                  end: 25,
                  options: { labelPos: 'right' }
                }
              ],
              datasets: [
                {
                  name: 'PM 2.5',
                  color: 'light-blue',
                  values: this.state.lastDay.map(result => result.pm25)
                }
              ]
            }}
          >
            {switchButtons}
          </LineChart>
      }
    }

    let lastWeekChart = null;

    if (this.state.loadingWeekly) {
      lastWeekChart = <Spinner/>;
    }

    if (this.state.errorWeekly) {
      lastWeekChart = <Error>Wystąpił błąd podczas pobierania danych. Spróbuj później...</Error>
    }

    if (!this.state.loadingWeekly && !this.state.errorWeekly) {
      const switchButtons = <Aux>
        <Button clickHandler={this.handleWeeklyChartSwitch} current={this.state.showChartLastWeekPm25}>PM 2.5</Button>
        <Button clickHandler={this.handleWeeklyChartSwitch} current={this.state.showChartLastWeekPm10}>PM 10</Button>
      </Aux>;

      if (this.state.showChartLastWeekPm10) {
        lastWeekChart =
            <LineChartWeekly
                title="PM 10 - ostatni tydzień"
                type="line"
                data={{
                  labels: this.state.lastWeek.map(result => result.timestamp.substring(0,10) + ' ' + result.hour + ':00'),
                  yRegions: [
                    {
                      label: "Norma",
                      start: 0,
                      end: 50,
                      options: { labelPos: 'right' }
                    }
                  ],
                  datasets: [
                    {
                      name: 'PM 10',
                      color: 'violet',
                      values: this.state.lastWeek.map(result => result.pm10)
                    }
                  ]
                }}
            >
              {switchButtons}
            </LineChartWeekly>
      }
      if (this.state.showChartLastWeekPm25) {
        lastWeekChart =
            <LineChartWeekly
                title="PM 2.5 - ostatni tydzień"
                type="line"
                data={{
                  labels: this.state.lastWeek.map(result => result.timestamp.substring(0,10) + ' ' + result.hour + ':00'),
                  yRegions: [
                    {
                      label: "Norma",
                      start: 0,
                      end: 25,
                      options: { labelPos: 'right' }
                    }
                  ],
                  datasets: [
                    {
                      name: 'PM 2.5',
                      color: 'light-blue',
                      values: this.state.lastWeek.map(result => result.pm25)
                    }
                  ]
                }}
            >
              {switchButtons}
            </LineChartWeekly>
      }
    }

    return (
      <Aux>
        {measureData}
        {lastDayChart}
        {lastWeekChart}
      </Aux>
    );
  }
}

export default Measures;