import React from 'react';
import classes from './Measure.css'
import Aux from './../../../hoc/Auxilary/Auxilary';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Measure extends React.Component {

    render() {
        let parentClasses = [classes.Measure];

        if(this.props.inline) {
            parentClasses.push(classes.MeasureInline);
        }
        let data = <Aux>
            <p className={classes.title}><FontAwesomeIcon className={classes.FontAwesome} icon={this.props.icon}/> {this.props.type}</p>
            <p className={classes.value}>{this.props.value} <span>{this.props.unit}</span></p>
        </Aux>;

        if(this.props.small) {
            data = <Aux><p className={classes.small}><FontAwesomeIcon className={classes.FontAwesome} icon={this.props.icon}/> {this.props.type}: {this.props.value}</p></Aux>;
        }
        return (
            <div className={parentClasses.join(' ')}>
                {data}
            </div>
        );
    }
}

export default Measure;