import React, {Component} from 'react'
import {AxisChart} from '../../../../node_modules/frappe-charts/dist/frappe-charts.min.esm';
import Aux from '../../../hoc/Auxilary/Auxilary';
import classes from './LineChart.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChartLine} from "@fortawesome/free-solid-svg-icons";

class LineChart extends Component {
    componentDidMount() {
        new AxisChart('#lastWeek', {
            parent: '#lastWeek',
            data: this.props.data,
            type: this.props.type,
            height: 350,
            lineOptions: {
                hideDots: 1
            },
            tooltipOptions: {
                formatTooltipY: d => d + ' µg/m³',
            },
            axisOptions: {
                xIsSeries: 1
            }
        })
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.title !== this.props.title) {
            new AxisChart('#lastWeek', {
                parent: '#lastWeek',
                data: this.props.data,
                type: this.props.type,
                height: 350,
                lineOptions: {
                    hideDots: 1
                },
                tooltipOptions: {
                    formatTooltipY: d => d + ' µg/m³',
                },
                axisOptions: {
                    xIsSeries: 1
                }
            })
        }
    }

    render() {

        return (
            <Aux>
                <div className={classes.LineChart}>
                    <p className={classes.Title}><FontAwesomeIcon className={classes.FontAwesome} icon={faChartLine}/>{this.props.title}</p>
                    {this.props.children}
                    <div id="lastWeek" />
                </div>
            </Aux>
        );
    }
}

export default LineChart;