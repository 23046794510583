import React from 'react';
import classes from './Button.css';

const button = (props) => {
  let cssClasses = [classes.Button];
  if(props.current) {
    cssClasses.push(classes.Active);
  }
  return (
    <button onClick={props.clickHandler} className={cssClasses.join(' ')}>{props.children}</button>
  )
};

export default button;