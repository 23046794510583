import React from 'react';
import classes from './Header.css';

const header = (props) => (
    <header className={classes.Header}>
        <h1>Pomiar zanieczyszczenia powietrza</h1>
        <h3>Stacja pomiarowa Dobrzykowice</h3>
    </header>
);

export default header;