import React from 'react';
import classes from './Measure.css'
import Aux from './../../../hoc/Auxilary/Auxilary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Measure extends React.Component {

    render() {
        let parentClasses = [classes.Measure];

        if(this.props.inline) {
            parentClasses.push(classes.MeasureInline);
        }

        const limitValue = this.props.limit.find((limit) => {
            return limit.min < this.props.value && limit.max >= this.props.value
        });

        const percentageValue = ((this.props.value/this.props.limit[1].max)*100).toFixed(2);

        const divStyle = {
            background: 'linear-gradient(90deg, ' + limitValue.color + ' ' +
              (percentageValue < 100 ? percentageValue : 100) + '%, transparent 0%)'
        };


        let data = <Aux>
            <p className={classes.title}><FontAwesomeIcon className={classes.FontAwesome} icon={this.props.icon}/> {this.props.type}</p>
            <p className={classes.value}>{this.props.value} <span>{this.props.unit}</span></p>
            <p className={classes.quality} style={{color: limitValue.color}}><span>Jakość powietrza: </span>{limitValue.quality}</p>
            <div className={classes.PercentageBar} style={divStyle}>{percentageValue} %</div>
        </Aux>;

        if(this.props.small) {
            data = <Aux><p className={classes.small}>{this.props.type}: {this.props.value}</p></Aux>;
        }
        return (
            <div className={parentClasses.join(' ')}>
                {data}
            </div>
        );
    }
}

export default Measure;