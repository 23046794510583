import React, { Component } from 'react';
import Layout from './hoc/Layout/Layout';
import { library } from '@fortawesome/fontawesome-svg-core';
import {faClock, faSmog, faTemperatureHigh, faTint} from '@fortawesome/free-solid-svg-icons';
import ReactGA from 'react-ga';

library.add(faSmog);
library.add(faTemperatureHigh);
library.add(faTint);
library.add(faClock);

class App extends Component {

  render() {
    ReactGA.initialize('UA-134530962-1');

    ReactGA.pageview('/');
    return (
        <Layout/>
    );
  }
}

export default App;
