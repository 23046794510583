import React, {Component} from 'react';
import Aux from '../Auxilary/Auxilary';
import classes from './Layout.css';
import Header from '../../components/UI/Header/Header';
import InfoBox from '../../components/UI/InfoBox/InfoBox';
import Measures from '../../containers/Measures/Measures'

class Layout extends Component {

  render() {
    return (
      <Aux>
        <Header/>
        <main className={classes.Content}>
          <Measures/>
          <InfoBox>
              Stacja pomiarowa umieszczona jest na Zielonym Osiedlu w Dobrzykowicach. Pomiary wykonywane są co 5 minut, a indeks jakości powietrza jest wyliczany na podstawie polskiego (ogólnego) indeksu jakości powietrza.
              <br />
              Stacja oparta jest na rozwiązaniu <a href="http://luftdaten.info">Luftdaten</a> opracowanym na Uniwersytecie w Stuttgarcie.
          </InfoBox>
          <InfoBox>
              Informacje dotyczące stacji pomiarowej oraz prezentowanych wyników można uzyskać pisząc na adres <a href="mailto:contact@smartgroup.eu">contact@smartgroup.eu</a>.
          </InfoBox>
        </main>
      </Aux>
    );
  }
}

export default Layout;